import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import firebase from 'firebase/app';
import { Redirect } from 'react-router-dom';
import {
  loadTalent,
  loadTalentUser,
  loadTalentSiblings,
  loadTalentInvitation,
  loadTalentAgency,
  loadTalentSubscription,
  loadTalentTransactions,
  updateTalent,
  resetPassword,
  updateTalentStatus,
  assignTalentToUser,
  cancelTalent,
  upgradeTalent,
  inviteTalent,
  flagTalentForApproval,
  updateUser,
} from 'actions';
import { isEmpty, isArray, get as getData } from 'lodash';

import { Modal, Text } from 'skybolt-ui';
import { updateTalentId, requiredSubscription, updateEpayAccount } from 'skybolt-api';
import { Link } from 'react-router-dom';
import UserSearchField from 'ui/UserSearchField';
import AgencySelect from 'ui/AgencySelect';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import NumberField from 'ui/NumberField';
import Button from 'ui/Button';
import Dialog from 'ui/Dialog';
import Field from 'ui/Field';
import Toggle from 'ui/Toggle';
import Loading from 'ui/Loading';
import Title from 'ui/Title';
import Subtitle from 'ui/Subtitle';
import Page from 'ui/Page';
import List from 'ui/List';
import Transaction from './Transaction';
// import DatePicker from 'material-ui/DatePicker';
import DateField from 'ui/DateField';
import TalentCard from 'ui/OldTalentCard';

import './index.css';





const mapStateToProps = (state, ownProps) => {
  const talentId = ownProps.match.params.talentId;
  const talent = state.talents.all[talentId];

  let user = null;
  if(talent && talent.userId) {
    user = state.users.all[talent.userId];
  }

  let agency = null;
  try {
    agency = state.agencies.all[talent.agencyId];
  }
  catch(e) {}

  const siblings = {};
  if(user) {
    for(const id in user.talents) {
      if(id !== talentId) {
        siblings[id] = state.talents.all[id];
      }
    }
  }

  let invitation = null;
  if(talent && talent.invitationId) {
    invitation = state.invitations[talent.invitationId];
  }

  const subscription = state.subscriptions.byTalent[talentId];
  const transactions = getData(state, `transactions.byTalent[${talentId}].data`, []);
  const isLoadingTransactions = getData(state, `transactions.byTalent[${talentId}].isLoading`, false);

  return {
    hasPermission: state.user.permissions.canAdminTalents,
    talentId,
    talent,
    agency,
    siblings,
    user,
    subscription,
    transactions,
    isLoadingTransactions,
    invitation,
    ...ownProps,
  };
};

const mapDispatchToProps = {
  loadTalent,
  loadTalentUser,
  loadTalentSiblings,
  loadTalentAgency,
  loadTalentSubscription,
  loadTalentTransactions,
  updateTalent,
  updateUser,
  resetPassword,
  updateTalentStatus,
  assignTalentToUser,
  cancelTalent,
  upgradeTalent,
  inviteTalent,
  loadTalentInvitation,
  flagTalentForApproval,
};





class TalentSettings extends Component {
  state = {
    isResetDialogOpen: false,
    isCancelDialogOpen: false,
    isCancelling: false,
    isUpgradeDialogOpen: false,
    isUpgrading: false,

    isInviteDialogOpen: false,
    inviteFirstname: "",
    inviteFirstnameError: "",
    inviteLastname: "",
    inviteLastnameError: "",
    inviteEmail: "",
    inviteEmailError: "",
    isInviting: false,
  }

  UNSAFE_componentWillMount() {
    this.props.loadTalent(this.props.talentId);
    this.props.loadTalentUser(this.props.talentId);
    this.props.loadTalentSiblings(this.props.talentId);
    this.props.loadTalentInvitation(this.props.talentId);
    this.props.loadTalentSubscription(this.props.talentId);
    this.props.loadTalentTransactions(this.props.talentId);
    this.props.loadTalentAgency(this.props.talentId);
  }



  resetPassword = () => {
    this.props.resetPassword(this.props.user.email);
    this.setState({isResetDialogOpen:false});
  }

  upgrade = async () => {
    const talentId = this.props.talent.id;

    this.setState({isUpgrading:true});

    const res = await this.props.upgradeTalent(talentId);

    if(!!res.code) {
      this.props.history.push(`/subscription/${this.props.talent.id}`);
    }

    this.setState({
      talentId:null,
      isUpdateDialogOpen:false,
      isUpgrading: false,
    });
  }

  cancel = async () => {
    const talentId = this.props.talent.id;

    this.setState({isCancelling:true});

    await this.props.cancelTalent(talentId);

    this.setState({
      isCancelDialogOpen:false,
      isCancelling: false,
    });
  }

  inviteUser = async () => {
    const talent = this.props.talent;

    if(!talent) {
      this.setState({
        inviteError: "No talent selected"
      });
      return;
    }

    let errors = {};

    if(!this.state.inviteFirstname) {
      errors.inviteFirstnameError = "A first name is required.";
    }

    if(!this.state.inviteLastname) {
      errors.inviteLastnameError = "A last name is required.";
    }

    if(!this.state.inviteEmail) {
      errors.inviteEmailError = "An email address is required.";
    }

    if(!isEmpty(errors)) {
      this.setState({
        inviteFirstnameError: "",
        inviteLastnameError: "",
        inviteEmailError: "",
        ...errors,
      });
      return;
    }

    this.setState({
      isInviting:true,
      inviteFirstnameError: "",
      inviteLastnameError: "",
      inviteEmailError: "",
    });

    try {
      await this.props.inviteTalent(
        this.state.inviteFirstname,
        this.state.inviteLastname,
        this.state.inviteEmail,
        talent.divisions || [],
        talent.id,
        talent.agencyId
      );

      this.setState({isInviting:true, isInviteDialogOpen:false});
    }
    catch(error) {
      this.setState({isInviting:false, inviteError:'Something went wrong. Please try again.'});
    }

  }

  expireIn26Days = async () => {
    const talentId = this.props.talent.id;
    const dateExpires = moment().add(26, 'days').valueOf();
    this.props.updateTalent(talentId, {dateExpires});
  }

  expireTomorrow = async () => {
    const talentId = this.props.talent.id;
    const dateExpires = moment().add(1, 'days').valueOf();
    this.props.updateTalent(talentId, {dateExpires});
  }

  changeAgency = async (agencyId, agency) => {
    const talentId = this.props.talentId;

    const doIt = await Modal.confirm(`
      Are you sure you want to change agency to ${agency.name}? 
      This will cancel the talent's subscription, and create a duplicate talent in ${agency.name}. 
      All media and events will be moved to the talent in ${agency.name}. 
    `);

    if(!doIt) {
      return;
    }

    Modal.alert("You will be redirected to the new talent in a moment.");
    
    try {
      await this.props.cancelTalent(talentId);
      let res = await firebase.functions().httpsCallable('talentsChangeAgency')({talentId, agencyId});
      let newTalentId = res.data;
      window.location.href = `/talents/${newTalentId}/settings`;
    }
    catch(e) {
      console.error(e);
      alert(e.message);
    }

  }

  incrementId = () => {
    const talentId = this.props.talentId;
    updateTalentId({talentId});
  }

  // checkRequiredSubscription = async () => {
  //   const sub = await requiredSubscription({talentId:this.props.talentId});
  //   const conf = await Modal.confirm(
  //     <div>
  //       <Text paragraph>Update epay account with:</Text>
  //       <pre>
  //         {JSON.stringify(sub, null, 2)}
  //       </pre>
  //     </div>
  //   );
  //   if(conf) {
  //     await updateEpayAccount(sub);
  //   }
  // }

  renderResetPasswordDialog() {
    return (
      <Dialog
        actions={[
          <Button key="no" raised={true} label="Nevermind" onClick={() => this.setState({isResetDialogOpen:false})} style={{marginRight:8}}/>,
          <Button key="yes" raised={true} primary={true} label="Yes, send it" onClick={this.resetPassword}/>
        ]}
        contentStyle={{maxWidth:500}}
        title="Are you sure?"
        modal={false}
        open={this.state.isResetDialogOpen}
        onRequestClose={() => this.setState({isResetDialogOpen:false})}>
        Send a reset password link to {this.props.user && this.props.user.email}?
      </Dialog>
    );
  }

  renderInviteUserDialog() {
    const talent = this.props.talent;
    const name = (talent && `${talent.firstName || ''} ${talent.lastName || ''}`.trim()) || "Unnamed";

    let actions = null;
    if(this.state.isInviting) {
      actions = [<Button key="loading" label="Hang Tight..."/>];
    }
    else {
      actions = [
        <Button key="no" label="Nevermind" onClick={()=> this.setState({isInviteDialogOpen:false})} style={{marginRight:8}}/>,
        <Button key="yes" raised={true} primary={true} label="Send It" onClick={this.inviteUser}/>
      ];
    }

    return (
      <Dialog
        actions={actions}
        contentStyle={{maxWidth:500}}
        title={`Invite New User to Manage ${name}`}
        modal={false}
        open={this.state.isInviteDialogOpen}
        onRequestClose={() => this.setState({isInviteDialogOpen:false})}>

        <Field label="First Name">
          <TextField
            name="invitefirstname"
            fullWidth
            value={this.state.inviteFirstname}
            errorText={this.state.inviteFirstnameError}
            style={{maxWidth:400}}
            onChange={(e, val) => this.setState({inviteFirstname:val, inviteError:""})}
          />
        </Field>

        <Field label="Last Name">
          <TextField
            name="invitelastname"
            fullWidth
            value={this.state.inviteLastname}
            errorText={this.state.inviteLastnameError}
            style={{maxWidth:400}}
            onChange={(e, val) => this.setState({inviteLastname:val, inviteError:""})}
          />
        </Field>

        <Field label="Email Address">
          <TextField
            name="inviteemail"
            fullWidth
            value={this.state.inviteEmail}
            errorText={this.state.inviteEmailError}
            style={{maxWidth:400}}
            onChange={(e, val) => this.setState({inviteEmail:val, inviteError:""})}
          />
        </Field>

        {this.state.inviteError &&
          <div style={{color:'red', fontSize:12, textAlign:'center', padding:16}}>
            {this.state.inviteError}
          </div>
        }

      </Dialog>
    );
  }

  // renderTransactions() {
  //   if(this.props.isLoadingTransactions) {
  //     return <Loading/>;
  //   }

  //   if(!isArray(this.props.transactions) || this.props.transactions.length === 0) {
  //     return <Page style={{padding:16}}>No Transactions</Page>;
  //   }

  //   return (
  //     <List style={{paddingBottom:40}}>
  //       {this.props.transactions.slice(0, 5).map(transaction =>
  //         <Transaction
  //           key={transaction.id}
  //           transaction={transaction}
  //         />
  //       )}
  //     </List>
  //   );
  // }

  render() {

    if(!this.props.hasPermission) {
      return <Redirect to="/"/>;
    }

    if(this.props.talent.isLoading) {
      return <Loading/>;
    }

    const {
      talent={},
      agency={},
      user={},
      siblings={},
      talentId,
      updateTalent,
      updateUser,
      assignTalentToUser,
      updateTalentStatus,
    } = this.props;

    const {
      isLoading,
      amount,
      cycle,
    } = this.props.subscription || {};

    let billing = null;
    if(!isLoading && !!amount && !!cycle) {
      billing = `$${parseFloat(amount).toFixed(2)}/${cycle.toLowerCase().indexOf('month') > -1 ? 'month' : 'year'}`;
    }

    return (
      <div>

        <Title>Settings</Title>

        <Subtitle>Agency</Subtitle>
        <Page pad={true}>
          <Field label="Agency">
            <AgencySelect
              value={talent.agencyId}
              onChange={this.changeAgency}
            />
          </Field>

          <Field label="Agency Contact">
            <UserSearchField
              name="agencycontactselect"
              hint={!!talent.agencyContact ? talent.agencyContact.name : "Search Agents"}
              filters={{
                agencyId: talent.agencyId,
                role: 'agent'
              }}
              onChange={val => {
                updateTalent(talentId, {
                  agencyContact:{
                    userId: val.userId,
                    name: val.userName,
                  }
                });
              }}
              fullWidth={true}
              style={{maxWidth:400}}
            />
          </Field>

          <Field label="Seeking Agency" valueStyle={{paddingTop:16}}>
            <Toggle
              name="seekingAgency"
              toggled={!!talent.isSeekingAgency}
              onToggle={(e, isSeekingAgency) => updateTalent(talentId, {isSeekingAgency})}
            />
          </Field>

        </Page>



        <Subtitle>User</Subtitle>
        <Page pad={true}>

          <Field label="User">
            <div>
              <UserSearchField
                name="userselect"
                hint={!!user ? `${user.firstName || ""} ${user.lastName || ""}`.trim() : null}
                onChange={val => assignTalentToUser(talentId, val.userId)}
                fullWidth={true}
                style={{maxWidth:400}}
              />
            </div>

          </Field>

          {!!user && !!user.email &&
            <Field label="User Email" valueStyle={{paddingTop:15}}>{user.email}</Field>
          }

          {!!user && !!user.legacyUsername &&
            <Field label="Legacy Username" valueStyle={{paddingTop:17}}>{user.legacyUsername}</Field>
          }

          {!!user && !!user.legacyUserId &&
            <Field label="Legacy User ID" valueStyle={{paddingTop:17}}>{user.legacyUserId}</Field>
          }

          <nav style={{display:'flex', marginTop:8, flexWrap:'wrap'}}>
            {!!user &&
              <Link to={`/admin/users/${user.id}`}>
                <Button raised={true} label="Open User" style={{marginRight:8, marginBottom:4}}/>
              </Link>
            }

            {!!user &&
              <Button
                raised={true}
                label="Send Reset Password Link"
                onClick={() => this.setState({isResetDialogOpen:true})}
                style={{marginRight:8, marginBottom:4}}
                disabled={!user}
              />
            }

            {!!user &&
              <Button
                onClick={() => {
                  if(!user) {
                    return;
                  }
                  let talents = user.talents;
                  user.talents[talentId] = null;
                  updateUser(user.id, {talents});
                  updateTalent(talentId, {userId:null});
                }}
                raised={true}
                label="Remove from User"
                style={{marginRight:8, marginBottom:4}}
              />
            }

            <Button
              raised={true}
              label="Invite User to Manage"
              style={{marginRight:8, marginBottom:4}}
              onClick={() => this.setState({isInviteDialogOpen:true})}
            />
          </nav>

        </Page>



        <Subtitle>Account</Subtitle>
        <Page pad={true}>
          <Field label="External ID">
            <TextField
              name="legacyid"
              fullWidth
              value={talent.legacyId || ""}
              style={{maxWidth:400}}
              onChange={(e, legacyId) => updateTalent(talentId, {legacyId: parseInt(legacyId, 10)})}
            />
            <Button
              raised={true}
              label="Assign New ID"
              onClick={this.incrementId}
            />
          </Field>

          <Field label="USAePay ID">
            <TextField
              name="epayId"
              fullWidth
              value={talent.epayId || ""}
              style={{maxWidth:400}}
              onChange={(e, epayId) => updateTalent(talentId, {epayId: parseInt(epayId, 10)})}
            />
          </Field>

          <Field label="Code">
            <TextField
              name="code"
              fullWidth
              value={talent.code || ""}
              style={{maxWidth:400}}
              onChange={(e, code) => updateTalent(talentId, {code})}
            />
          </Field>

          {/* <Field label="USAePay" valueStyle={{paddingTop:15}}>{this.props.subscription && this.props.subscription.enabled ? "Enabled" : "Disabled"}</Field>
          {billing && <Field label="Billing" valueStyle={{paddingTop:15}}>{billing}</Field>} */}

          {/* <Field label="USAePay ID">
            <TextField
              name="epayId"
              fullWidth
              hintText="USAePay Id"
              value={talent.epayId || ""}
              style={{maxWidth:400}}
              onChange={(e, epayId) => updateTalent(talentId, {epayId})}
            />
          </Field> */}
          
          {user && user.id && 
            <Field label="Stripe ID">
              <TextField
                name="stripeCustomerId"
                fullWidth
                hintText="The Stripe Customer Id"
                value={user.stripeCustomerId || ""}
                style={{maxWidth:400}}
                onChange={(e, stripeCustomerId) => updateUser(user.id, {stripeCustomerId})}
              />
            </Field>
          }

          {/* {talent.billingVendorId && (
            <div>
              <Field label="Bill.com Vendor ID">
                <TextField
                  name="billingVendorId"
                  fullWidth
                  value={talent.billingVendorId || ""}
                  style={{maxWidth:400}}
                  onChange={(e, billingVendorId) => updateTalent(talentId, {billingVendorId})}
                />
              </Field>
            </div>
          )}

          {talent.billing1099VendorId && (
            <div>
              <Field label="Bill.com 1099 Vendor ID" valueStyle={{display:'flex', gap:'16px'}}>
                <TextField
                  name="billing1099VendorId"
                  fullWidth
                  value={talent.billing1099VendorId || ""}
                  style={{maxWidth:400}}
                  onChange={(e, billing1099VendorId) => updateTalent(talentId, {billing1099VendorId})}
                />
              </Field>
            </div>
          )}

          {(talent.billingVendorId || talent.billing1099VendorId) && (
            <Field label="">
              <Button
                raised={true}
                label="Re-Sync Bill.com Vendor"
                onClick={() => null}
                style={{marginRight:8, marginBottom:4}}
              />
            </Field>
          )} */}

          {/* <Field label="Billing Agency ID">
            <TextField
              name="legacyAgencyId"
              fullWidth
              hintText={agency.legacyId}
              value={talent.legacyAgencyId || ""}
              style={{maxWidth:400}}
              onChange={(e, legacyAgencyId) => updateTalent(talentId, {legacyAgencyId})}
            />
          </Field> */}

          <Field label="Status">
            <SelectField
              name="status"
              fullWidth
              style={{maxWidth:400}}
              value={talent.status}
              onChange={(e, i, status) => updateTalentStatus(talentId, status)}>
              <MenuItem value="INCOMPLETE" primaryText="Incomplete"/>
              <MenuItem value="TRIAL"  primaryText="Trial"/>
              <MenuItem value="PAID" primaryText="Paid"/>
              <MenuItem value="EXPIRED"  primaryText="Expired"/>
              <MenuItem value="INACTIVE" primaryText="Inactive"/>
              <MenuItem value="CANCELED" primaryText="Canceled"/>
            </SelectField>

            <div className="talentsettings-status">
              {Object.keys(talent.statusHistory || {})
                .sort((a, b) => a > b ? -1 : 1)
                .map(date => ({date:parseInt(date, 10), status:talent.statusHistory[date]}))
                .map(status =>
                  <div key={status.date}>
                    <span className="talentsettings-status-status">{status.status}</span>
                    <span className="talentsettings-status-date">{moment(status.date).format("MMMM Do YYYY, h:mm:ss a")}</span>
                  </div>
                )
              }
            </div>
          </Field>

          {!!agency.requireTalentApproval &&
            <Field label="Approved" valueStyle={{paddingTop:16}}>
              <Toggle
                name="approved"
                toggled={!!talent.approved}
                onToggle={(e, approved) => updateTalent(talentId, {approved})}
              />
            </Field>
          }

          <Field label="Require Subscription" valueStyle={{paddingTop:16}}>
            <Toggle
              name="requiresubscription"
              toggled={!!talent.requireSubscription}
              onToggle={(e, toggled) => updateTalent(talentId, {requireSubscription: toggled ? true : null})}
            />
            <Text small color="textLight" block style={{paddingTop:8}}>
              Prompt {talent.firstName} {talent.lastName} to choose a plan the
              next time they log in.
            </Text>
          </Field>

          {/* <Field label="Plan">
            <SelectField
              name="plan"
              fullWidth
              style={{maxWidth:400}}
              value={talent.plan ? talent.plan.id : null}
              onChange={(e, i, planId) => updateTalent(talentId, {plan:planId ? agency.plans[planId] : null})}>

              <MenuItem value={null}  primaryText="None"/>

              {Object.keys(agency.plans || {}).map(planId =>
                <MenuItem key={planId} value={planId}  primaryText={`${agency.plans[planId].name} (${agency.plans[planId].cycle})`}/>
              )}
            </SelectField>
            <div style={{fontSize:12, maxWidth:400, color:"#aaa"}}>
              Change the plan associated with this talent. This will not change
              their billing, only the plan displayed for the talent.
            </div>
          </Field> */}

          {/* <Field label="Require Addons" valueStyle={{paddingTop:16}}>
            <Toggle
              name="requireaddons"
              toggled={!!talent.requireAddons}
              onToggle={(e, toggled) => updateTalent(talentId, {requireAddons: toggled ? true : null})}
            />
            <Text small color="textLight" block style={{paddingTop:8}}>
              Prompt {talent.firstName} {talent.lastName} to choose addons the
              next time they log in. Addon options are determined by their selected plan.
            </Text>
          </Field> */}

          <Field label="Expiration Date" valueStyle={{paddingTop:11}}>
            <DateField
              key={talent.dateExpires}
              name="expirationdate"
              initialValue={talent.dateExpires}
              onChange={dateExpires => updateTalent(talentId, {dateExpires})}
              underline={true}
              style={{maxWidth:400}}
              clearable
            />
          </Field>

          {/* <Field label="Commission">
            <NumberField
              name="commission"
              fullWidth
              value={talent.commission || ""}
              style={{maxWidth:400}}
              onChange={(e, commission) => updateTalent(talentId, {commission})}
            />
          </Field>

          <Field label="Recent Transactions" valueStyle={{paddingTop:11}}>
            {this.renderTransactions()}
          </Field> */}



          <nav style={{display:'flex', marginTop:8, flexWrap: 'wrap'}}>
            {/* {(talent.status === 'PAID' || talent.status === 'EXPIRED' || talent.status === 'INACTIVE' || talent.status === 'TRIAL') &&
              <Button
                raised={true}
                label="Cancel"
                onClick={() => this.setState({isCancelDialogOpen:true})}
                style={{marginRight:8, marginBottom:4}}
              />
            } */}

            {/* {talent.status === 'TRIAL' &&
              <Button
                raised={true}
                label="Upgrade"
                onClick={() => this.setState({isUpgradeDialogOpen:true})}
                style={{marginRight:8, marginBottom:4}}
              />
            }

            {talent.status === 'CANCELED' &&
              <Button
                raised={true}
                label="Restart"
                onClick={() => this.setState({isUpgradeDialogOpen:true})}
                style={{marginRight:8, marginBottom:4}}
              />
            } */}

            {!talent.requiresApproval &&
              <Button
                raised={true}
                label="Flag for approval"
                onClick={() => this.props.flagTalentForApproval(talentId)}
                style={{marginRight:8, marginBottom:4}}
              />
            }

            {!talent.dateExpires &&
              <Button
                raised={true}
                label="Expire tomorrow"
                onClick={this.expireTomorrow}
                style={{marginRight:8, marginBottom:4}}
              />
            }

            {!talent.dateExpires &&
              <Button
                raised={true}
                label="Expire in 26 days"
                onClick={this.expireIn26Days}
                style={{marginRight:8, marginBottom:4}}
              />
            }

            {/* <Button
              raised={true}
              label="Check Required Subscription"
              onClick={this.checkRequiredSubscription}
              style={{marginRight:8, marginBottom:4}}
            /> */}

          </nav>

        </Page>

        <Subtitle>Features</Subtitle>
        <Page pad={true}>
           <Field label="Headshot" valueStyle={{paddingTop:16}}>
            <Toggle
              style={{opacity: talent.canHeadshot === undefined ? 0.2 : 1}}
              name="canHeadshot"
              toggled={talent.canHeadshot !== false}
              onToggle={(e, toggled) => updateTalent(talentId, {canHeadshot: toggled ? true : false})}
            />
            <Text small color="textLight" block style={{paddingTop:8}}>
              Allow {talent.firstName} {talent.lastName} to add and update a headshot image. <br/>
              Changing this setting does not update the talent's billing.
            </Text>
          </Field>
          <Field label="Media" valueStyle={{paddingTop:16}}>
            <Toggle
              style={{opacity: talent.canMedia === undefined ? 0.2 : 1}}
              name="canMedia"
              toggled={talent.canMedia !== false}
              onToggle={(e, toggled) => updateTalent(talentId, {canMedia: toggled ? true : false})}
            />
            <Text small color="textLight" block style={{paddingTop:8}}>
              Enables media library for {talent.firstName} {talent.lastName}.<br/>
              Changing this setting does not update the talent's billing.
            </Text>
          </Field>
          <Field label="Video" valueStyle={{paddingTop:16}}>
            <Toggle
              style={{opacity: talent.canVideo === undefined ? 0.2 : 1}}
              name="canVideo"
              toggled={talent.canVideo !== false}
              onToggle={(e, toggled) => updateTalent(talentId, {canVideo: toggled ? true : false})}
            />
            <Text small color="textLight" block style={{paddingTop:8}}>
              Allow {talent.firstName} {talent.lastName} to upload video to their media library. <br/>
              Changing this setting does not update the talent's billing.
            </Text>
          </Field>
        </Page>


        <Subtitle>Related Talent</Subtitle>
        <div>
          {Object.keys(siblings).map(siblingId =>
            <div key={siblingId} style={{marginBottom:4}}>
              <TalentCard
                zDepth={1}
                talent={siblings[siblingId]}
              />
            </div>
          )}
        </div>

        {/* {this.renderCancelDialog()} */}
        {/* {this.renderUpgradeDialog()} */}
        {this.renderResetPasswordDialog()}
        {this.renderInviteUserDialog()}

      </div>
    );
  }
}





export default connect(mapStateToProps, mapDispatchToProps)(TalentSettings);